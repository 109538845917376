@use '@/styles/utils/mixins.scss' as *;

.wizardCard {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  padding: 3.75rem;
  border-radius: 1.25rem;
  background-color: $white;

  @include tab() {
    width: calc(100% + 3.75rem);
    margin: 0 -1.875rem;
  }

  @include mob() {
    padding: 3.75rem 1.875rem;
  }
  .sectionHeadingContainer {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }
  .sectionDescription {
    max-width: 43.5rem;
  }

  .stepWizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .stepsContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      gap: 1.25rem;
      @include mob() {
        display: flex;
        gap: 0;
        padding: 0 1.875rem;
      }
    }

    .step {
      display: flex;
      gap: 1.25rem;
      align-items: center;
      @include mob() {
        gap: 0;
        flex: 1 1 auto;
      }

      &::after {
        content: '';
        background-color: $neutral03;
        width: 100%;
        height: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid $neutral03;
        @include mob() {
          border-radius: 0rem;
          margin: 0 -1px;
          width: calc(100% + 2px);
        }
      }

      @include mob() {
        &:last-child {
          flex: 0 0 auto;
          &::after {
            display: none;
          }
        }
      }

      .stepNumber {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 400;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        color: $dark;
        background-color: $neutral03;
        border: 1px solid $neutral03;
        flex: 0 0 auto;
      }

      &.completed {
        .stepNumber {
          color: $white;
          background-color: $primary;
          border-color: $primary;
        }
        &::after {
          background-color: $primary;
          border-color: $primary;
        }
      }

      &.active {
        .stepNumber {
          color: $primaryAlt;
          background-color: $white;
          border-color: $primaryAlt;
        }
        &::after {
          background-color: $white;
          border-color: $primaryAlt;
        }
      }
    }

    .stepContent {
      margin: 3.75rem 0;
      width: 100%;
    }

    .wizardFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 1.875rem;

      @include mob() {
        flex-direction: column;
        align-items: center;
        gap: 1.875rem;
        padding: 0 1.875rem;
      }
    }
    .wizardBtnNavigation {
      display: flex;
      gap: 1.875rem;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      @include mob() {
        flex-direction: column;
      }
    }
    .backBtn {
      font-size: 1rem;
      font-weight: 500;
      color: $dark;
      text-align: center;
      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }
}
